import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { Speerpunt, TagVariant } from "./types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function reverseDateFormat(date: any) {
  if (!date) return "";
  // Check if the format is YYYY-MM-DD or DD-MM-YYYY by looking at the first part
  const dateParts = date.split("-");

  if (dateParts[0].length === 4) {
    // If the first part has 4 digits, it's in YYYY-MM-DD format
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  } else {
    // Otherwise, it's in DD-MM-YYYY format
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  }
}

export function getUniqueTags(data: Speerpunt[]): TagVariant[] {
  const tagsSet: Set<TagVariant> = new Set();
  data.forEach((item) => {
    item.attributes.tags.forEach((tag) => {
      tagsSet.add(tag as any);
    });
  });
  return Array.from(tagsSet);
}

export function strapiMediaUrl(image: any) {
  if (!Array.isArray(image?.data) && !image?.data) return image?.attributes?.url;

  if (image?.data?.length > 0) {
    return image?.data.map((img: any) => {
      return img?.attributes?.url;
    });
  }
  if (image?.data?.attributes?.url === undefined) return null;
  return image?.data?.attributes?.url;
}

export function strapiMediaAlt(image: any) {
  if (!Array.isArray(image?.data) && !image?.data) return image?.attributes?.alternativeText;

  if (image?.data?.length > 0) {
    return image?.data.map((img: any) => {
      return img?.attributes?.alternativeText;
    });
  }
  if (image?.data?.attributes?.alternativeText === undefined || image?.data?.attributes?.alternativeText === null)
    return "no alt text";
  return image?.data?.attributes?.alternativeText;
}

export function videoDetector(url: string) {
  const videoExtensions = [
    "mp4",
    "webm",
    "ogg",
    "mov",
    "avi",
    "wmv",
    "flv",
    "mkv",
    "3gp",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
  ];
  const extension = url.split(".").pop() || "";
  return videoExtensions.includes(extension);
}

export function getBgColor(color: string) {
  switch (color) {
    case "whitesmoke":
      return "#F2F2F2";
    case "white":
      return "#ffffff";
    case "yellow":
      return "#ffd000";
    case "dark_blue":
      return "#171c60";
    case "blue":
      return "#01339b";
    case "ice_blue":
      return "#d1e3f1";
    case "mint":
      return "#79c6bd";
  }
}

export function getTextColor(color: string | undefined) {
  switch (color) {
    case "whitesmoke":
      return "#171c60";
    case "white":
      return "#171c60";
    case "yellow":
      return "#171c60";
    case "blue":
      return "#ffffff";
    case "dark_blue":
      return "#ffffff";
    case undefined:
      return "#171c60";
  }
}
