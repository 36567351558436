import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import Link from "next/link";
import Arrow from "../icons/Arrow";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap header-6 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-yellow text-dark-blue hover:bg-yellow-hover",
        secondary: "bg-dark-blue text-white hover:bg-dark-blue-hover",
        link: "link p-0",
        ghost: "bg-transparent text-dark-blue",
        mint: "bg-mint text-dark-blue",
        orange: "bg-orange text-dark-blue",
        "ice-blue": "bg-ice-blue text-dark-blue",
        blue: "bg-blue text-white",
        red: "bg-red text-white",
      },
      size: {
        default: "h-[40px] px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-12 px-8",
        icon: "h-10 w-10",
        round: "rounded-full h-[40px] w-[40px]",
        link: "p-0 w-fit",
      },
      fullWidth: {
        true: "w-full",
        false: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      fullWidth: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, fullWidth, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = "Button";

export interface ButtonLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {
  href: string;
  arrowColor?: string;
}

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      className,
      variant = "link",
      size = "link",
      fullWidth,
      href,
      children,
      arrowColor = "#171C60",
      target = "_self",
      ...props
    },
    ref
  ) => {
    return (
      <Link
        href={href}
        className={cn(buttonVariants({ variant, size, fullWidth, className }), "group flex")}
        ref={ref}
        target={target}
        {...props}
      >
        <>
          <span className="text-wrap leading-6">{children}</span>{" "}
          {variant === "link" && (
            <Arrow color={arrowColor} className="ml-3 min-w-fit transition-transform group-hover:translate-x-1" />
          )}{" "}
        </>
      </Link>
    );
  }
);

ButtonLink.displayName = "ButtonLink";

export { Button, ButtonLink, buttonVariants };
