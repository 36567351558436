"use client";

import { useMotionValueEvent, motion, useScroll } from "framer-motion";
import { useState } from "react";

import { ButtonLink } from "./ui/button";

const NotFoundComponent = () => {
  const [hideTopHeader, setHideTopHeader] = useState(false);
  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, "change", (latest) => {
    if ((latest as any) > 100) {
      setHideTopHeader(true);
    } else {
      setHideTopHeader(false);
    }
  });

  return (
    <div>
      <motion.div
        animate={{ y: hideTopHeader ? -40 : 0 }}
        transition={{
          duration: 0.5,
          ease: [0.16, 1, 0.3, 1],
        }}
        className="fixed top-0 h-[118px] w-full bg-dark-blue"
      />
      <section className="flex h-full min-h-[calc(100vh-118px)] flex-col gap-0">
        <div className="container flex min-h-[calc(100vh-118px)] max-w-[1000px] flex-col items-center justify-center gap-4">
          <h1 className="header-1">404</h1>
          <p>De pagina die je zocht bestaat niet.</p>
          <ButtonLink variant="default" size="default" href="/">
            Terug naar de homepage
          </ButtonLink>
        </div>
      </section>
    </div>
  );
};

export default NotFoundComponent;
